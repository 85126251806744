@import '@/styles/breakpoints.module';

.ctaContainer {
  text-align: center;
}

.ctaButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

// TODO
.placeholder {
  width: 100%;
  background-color: black;
  border-radius: var(--palette-border-radius-l);
  overflow: hidden;

  .imgWrapper {
    .description {
      position: relative;
      z-index: 1;
      text-align: center;
      padding: 12px 0;
      color: var(--color-btn-primary-text);
      font-size: 11.752px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0.353px;

      @include tablet {
        font-size: 22.736px;
        letter-spacing: 0.682px;
        padding: 25px 0 20px;
      }

      @include desktop {
        font-size: 40px;
        letter-spacing: 1.2px;
        padding: 44px 0 35px;
      }
    }

    img {
      width: 100%;
    }
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.disclaimer {
  margin-top: 16px;
  text-align: center;

  font-size: 14px;
  color: var(--palette-gray-500) !important;
}
