@import '@/styles/breakpoints.module';

.carouselsBlock {
  position: relative;
  overflow: hidden;

  mask-image: linear-gradient(
    to right,
    rgba(15, 15, 15, 0.2),
    rgba(15, 15, 15, 1) 10%,
    rgba(15, 15, 15, 1) 90%,
    rgba(15, 15, 15, 0.2)
  );

  @include tablet {
    mask-image: linear-gradient(
      to right,
      rgba(15, 15, 15, 0.1),
      rgba(15, 15, 15, 1) 20%,
      rgba(15, 15, 15, 1) 80%,
      rgba(15, 15, 15, 0.1)
    );
  }
}

.carouselRow {
  position: relative;
  width: 100%;
  overflow: hidden;

  &:not(:first-child) {
    margin-top: 14px;
  }

  @include tablet {
    &:not(:first-child) {
      margin-top: 22px;
    }
  }
}

.list {
  display: flex;
  align-items: center;
  width: fit-content;
  padding-block: 2px;
  gap: 16px;
  @include tablet {
    gap: 24px;
  }
}

.item {
  flex-shrink: 0;
}
