@use '@/styles/rtl.module';
@import '@/styles/breakpoints.module';

.section {
  position: relative;
  overflow: hidden;
}

.containerLeft {
  width: 100%;
  max-width: 1330px;
  margin-inline-start: 16px;

  @include tablet {
    margin-inline-start: max(40px, calc((100% - 1140px) / 2));
  }
}

.container {
  //--swiper-wrapper-transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
  width: 100%;
  position: relative;
}

.slide.slide {
  position: relative;
  flex-shrink: 0;
  width: 100%;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include tablet {
    width: 653px;
    height: 436px;
  }
}

.content {
  opacity: 0.5;
  transition: all 0.4s ease;

  &.active {
    opacity: 1;
  }

  @include tablet {
    scale: 0.85;

    &.active {
      scale: 1;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerContent {
  @include tablet {
    max-width: 653px;
  }
}

.headerArrows {
  display: none;

  @include tablet {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
}

.bottomArrows {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  @include tablet {
    display: none;
  }
}

.prev,
.next {
  cursor: pointer;
}
