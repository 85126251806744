@import '@/styles/breakpoints.module';

.modalHeading {
  padding-bottom: 12px;
  padding-top: 16px;
  position: sticky;
  top: 0;
  z-index: 3;
  background: var(--palette-white);

  @include tablet {
    padding-bottom: 24px;
    padding-top: 32px;
  }
}

.modalDescription {
  margin-bottom: 16px;
  & > *:not(:last-child) {
    margin-bottom: 8px;
    @include tablet {
      margin-bottom: 16px;
    }
    @include desktop {
      margin-bottom: 12px;
    }
  }
}

.holidaysDialog {
  & :global .MuiPaper-root {
    padding-top: 0 !important;
  }
}
