@import '@/styles/breakpoints.module';

.scrollMargin {
  scroll-margin-top: calc(20px + var(--height-app-banner));
}

.slider {
  --swiper-wrapper-transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
  max-width: 1140px !important;
  margin: 0 !important;
}

.wrapper {
  max-width: 100%;
  position: relative;
  padding-inline: 0;
  margin-inline: auto;
  @include tablet {
    padding-inline: 40px;
  }
}

.sliderWrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
}

.slide.slide {
  width: 100%;
  height: unset;
  pointer-events: auto;
  user-select: none;
  margin-bottom: 2px;
}

.paginationWrapper {
  display: block;
}

.pagination {
  position: relative;
  margin-inline: auto;
  width: max-content;
  margin-top: 24px;
  @include tablet {
    margin-top: 30px;
  }
}

.prev,
.next {
  cursor: pointer;
  display: none;
  @include desktop {
    display: flex;
  }
}
