@import '@/styles/breakpoints.module';

.arrowButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;

  transition:
    background 0.1s ease-in-out,
    color 0.1s ease-in-out,
    scale 0.2s;

  svg {
    transition:
      background 0.1s ease-in-out,
      color 0.1s ease-in-out,
      scale 0.2s;
  }
}

.arrow {
  --arrow-bg: rgba(144, 143, 143, 0.2);
  --arrow-hover-bg: rgba(144, 143, 143, 0.35);
  --arrow-active-bg: rgba(144, 143, 143, 0.45);

  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: var(--border-radius-slider-arrow-button);
  color: white;
  background: var(--arrow-bg);
  backdrop-filter: blur(10px);

  svg {
    width: 24px;
    height: auto;
  }

  &:hover {
    background: var(--arrow-hover-bg);
  }

  &:active {
    background: var(--arrow-active-bg);
  }

  @include tablet {
    width: 72px;
    height: 72px;
    padding: 20px;

    svg {
      width: 32px;
    }
  }
}

.chevron {
  width: 40px;
  height: 40px;
  color: var(--slider-chevron-color);
  background: transparent;

  svg {
    width: 32px;
    height: auto;
  }

  &:hover {
    svg {
      color: var(--slider-chevron-hover-color);
    }
  }

  &:active {
    svg {
      color: var(--slider-chevron-active-color);
      scale: 0.93;
    }
  }

  @include tablet {
    width: 56px;
    height: 56px;

    svg {
      width: 48px;
    }
  }
}
