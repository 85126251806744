.swiper-pagination-bullet {
  display: inline-block;
  height: 12px;
  width: 12px;
  margin: 0 4px;
  background: var(--slider-pagination-bullet-color);
  border-radius: 100%;
  opacity: var(--slider-pagination-bullet-opacity);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.swiper-pagination-bullet-active {
  width: 41px;
  background: var(--slider-pagination-bullet-active-color);
  border-radius: 24px;
  opacity: var(--slider-pagination-bullet-active-opacity);
}
