@import '@/styles/breakpoints.module';

.slider {
  display: block;
  @include tablet {
    display: none;
  }
}

.slide.slide {
  width: 100%;
  height: unset;
  pointer-events: auto;
  user-select: none;
  margin-bottom: 2px;
}

.bordered {
  border-radius: 12px;
  border: 1px solid var(--palette-gray-600);
}

.borderless {
  border-radius: 0;
  border-top: 1px solid var(--palette-gray-600);
  border-bottom: 1px solid var(--palette-gray-600);

  &:first-of-type {
    border-radius: 12px 0 0 12px;
    border-inline-start: 1px solid var(--palette-gray-600);
  }

  &:last-of-type {
    border-radius: 0 12px 12px 0;
    border-inline-end: 1px solid var(--palette-gray-600);
  }

  & + & {
    border-inline-start: 1px solid var(--palette-gray-600);
  }
}
