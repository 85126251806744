@import '@/styles/breakpoints.module';

.dialog {
  :global .MuiPaper-root {
    max-width: 552px;
    width: 100%;
  }
}

.mt5Modal {
  padding: var(--padding);
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 12px;

  @include tablet {
    gap: 24px;
  }
}

.mt5Heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  .mt5Close {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 3px;
    max-height: 30px;
    max-width: 30px;
    height: 100%;
    width: 100%;

    svg {
      height: 100%;
      width: 100%;
    }
  }
}

.mt5Info {
  display: flex;
  flex-direction: column;

  gap: 8px;

  @include tablet {
    gap: 16px;
  }
}

.mt5Code {
  width: 100%;
  max-width: 258px;
  border-radius: 4px;
  border: 1px solid var(--palette-gray-100);
  overflow: hidden;

  @include tablet {
    border-radius: 8px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
