@import '@/styles/breakpoints.module';

.wrapper {
  br {
    display: none;
    @include tablet {
      display: block;
    }
  }
}
