@import '@/styles/breakpoints.module';

.wrapperItem {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  gap: 12px;

  &:first-child {
    position: sticky;
    top: 56px;

    @include tablet{
      top: 92px;
    }
  }

  &:nth-child(even) {
    background-color: var(--palette-gray-70);
  }

  &:nth-child(odd) {
    background-color: var(--palette-white);
  }

  .tableItemName {
    text-align: start;
    color: var(--palette-gray-900);
  }

  .tableTitle {
    color: var(--palette-gray-400);
    font-weight: var(--palette-font-weight-semibold);
  }

  .tableItem {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-weight: var(--palette-font-weight-medium);
    color: var(--palette-gray-900);
  }
}
