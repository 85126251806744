@import '@/styles/breakpoints.module';

.product-slider {
  //--swiper-wrapper-transition-timing-function: linear;
  position: relative;
  width: 100%;
  max-width: 1176px;
  margin-inline: auto;

  .swiper-wrapper {
    pointer-events: none;
  }

  .swiper-slide {
    pointer-events: auto;
  }
}
