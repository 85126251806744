@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.title {
  @include typography.h6Tablet_;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.subContent {
  gap: 4px;
}

.accent {
  font-weight: var(--palette-font-weight-medium);
  color: var(--palette-gray-900);
}

.exclamations .accent {
  font-weight: var(--palette-font-weight-semibold);
}

.calculationsContainer {
  display: grid;
  gap: 16px;

  .wrapperHeading {
    gap: 4px;
  }

  grid-template-columns: 1fr;
  @include desktop {
    grid-template-columns: 386px 1fr;
  }
}

.calculatorWrapper {
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--palette-primary-700);
  color: var(--palette-white);

  width: 40px;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 12px;
  @include tablet {
    width: 56px;
    padding: 12px;
    border-radius: 12px;
    margin-bottom: 24px;
  }

  svg {
    width: 24px;
    height: 24px;

    @include tablet {
      width: 32px;
      height: 32px;
    }
  }
}

.formulaContent {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  p {
    margin-bottom: 12px;

    @include tablet {
      margin-bottom: 16px;
    }
  }
}

.starWrapper {
  color: var(--palette-primary-700);
  display: flex;
  align-items: center;
}
