@import '@/styles/breakpoints.module';

.scrollMargin {
  scroll-margin-top: calc(20px + var(--height-app-banner));
}

.container {
  position: relative;
}

.sectionTitle {
  margin-bottom: 16px;

  @include tablet {
    margin-bottom: 24px;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  justify-items: center;
  justify-content: center;

  @include tablet {
    grid-template-columns: auto auto;
  }
}

.slide.slide {
  width: 100%;
  height: 360px;
  pointer-events: auto;
  margin-bottom: 2px;

  @include tablet {
    width: 360px;
    height: 440px;
  }
}

.navigation {
  display: none;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  @include desktop {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.navigationContainer {
  display: flex;
  justify-content: space-between;
  max-width: 1140px;
  width: 100%;
  margin-inline: 40px;
}

.prevSlide,
.nextSlide {
  position: absolute;
  top: 43%;
  z-index: 10;
  margin: 0;

  &:after {
    content: '';
  }
}

.prevSlide {
  inset-inline-start: 0;
}

.nextSlide {
  inset-inline-end: 0;
}

.pagination {
  position: relative;
  margin-inline: auto;
  width: max-content;
  margin-top: 24px;
  @include tablet {
    margin-top: 30px;
  }
}
